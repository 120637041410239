import Card from 'components/Card';
import Link from 'next/link';
import PropTypes from 'prop-types';

function RelatedCompanyBranches({ relatedCompanyBranches, companyName }) {
  if (!relatedCompanyBranches.length || !companyName) return null;

  return (
    <Card extraClasses="c-related-company-branches c-sidebar__card">
      <h2>Bekijk andere filialen van {companyName}</h2>
      <ul className="o-list-clean u-margin-bottom-none">
        {relatedCompanyBranches.map(branch => (
          <li
            className="c-related-company-branches__list-item"
            key={branch.slug}
          >
            <Link
              href={`/vacatures/${branch.slug}`}
              className="u-umbrella-link h4"
            >
              {branch.name}
            </Link>
            <span className="c-related-company-branches__count">
              {branch.count} openstaande vacatures
            </span>
          </li>
        ))}
      </ul>
    </Card>
  );
}

RelatedCompanyBranches.propTypes = {
  companyName: PropTypes.string.isRequired,
  relatedCompanyBranches: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default RelatedCompanyBranches;
